import type { FeatureFlagsOptions } from '@cosmos/feature-flags';

import { releaseFlags } from './release-features';

export const featureFlagsToolbarOptions: FeatureFlagsOptions = {
  development: {
    title: 'Development',
    color: '#c23f22',
    flags: {
      post_mmp: true,
      stores: true,
      feedBack: false,
      tbd: true,
      qwik: false,
      websitesMMP: true,
      custom_domains: true,
      custom_domains_sites: true,
      custom_domains_stores: false,
      inspire_seo_sites: true,
      inspire_seo_stores: true,
      inspire_search: true,
      inspire_presentation_description: true,
      custom_products: true,
      orders_table_event_type_filter: true,
      aggregated_po_margin: true,
      web_store_project_invoices: true,
      website_payment_methods: true,
      website_account_management: true,
      vs_migration: true,
      vs_text_editor: true,
      vs_retouch_tool: true,
      company_enrichments: true,
      confetti: true,
      crm_global_notes_page: true,
      settings_third_party_system_name: true,
      settings_create_roles: true,
      settings_orders: true,
      permissions_create_po: true,
      permissions_create_invoice: true,
      notification_project_deleted: true,
      presentation_pdp_more_info_from_velocity_poc: true,
      store_pdp_more_info_from_velocity_poc: true,
      store_pdp_more_info_from_velocity_new_charges_table: true, // store_pdp_more_info_from_velocity_poc is parent for this flag
      pay_with_points: true,
      product_detail_supplier_minority: true,
      stores_product_options: true,
      edit_order_product_redesign: true,
      corporate_collection: true,
      add_to_store_flow: true,
    },
  },
  release: {
    title: 'Release',
    color: '#09755d',
    flags: releaseFlags,
  },
};
