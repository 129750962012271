import type {
  HeaderMenuItemOverrideSettings,
  HeaderMenuItemsKeyValue,
  HeaderMenuTypes,
} from '@esp/misc/types-menu-items';

import {
  accountsSharedMenuItem,
  collectionsSharedMenuItem,
  crmSharedMenuItem,
  externalResourceSharedMenuItem,
  feedbackSharedMenuItem,
  helpCenterSharedMenuItem,
  MenuType,
  mySiteSharedMenuItem,
  notificationsSharedMenuItem,
  presentationsSharedMenuItem,
  projectsSharedMenuItem,
  quoteOrdersSharedMenuItem,
  storesSharedMenuItem,
  websitesSharedMenuItem,
} from './shared-menu-items.config';
import { constructMenu } from './utils';

export const availableHeaderMenuItems: HeaderMenuItemsKeyValue = {
  feedback: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    feedbackSharedMenuItem(MenuType.Header, overrides),
  collections: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    collectionsSharedMenuItem(MenuType.Header, overrides),
  projects: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    projectsSharedMenuItem(MenuType.Header, overrides),
  crm: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    crmSharedMenuItem(MenuType.Header, overrides),
  websites: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    websitesSharedMenuItem(MenuType.Header, overrides),
  stores: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    storesSharedMenuItem(MenuType.Header, overrides),
  helpCenter: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    helpCenterSharedMenuItem(MenuType.Header, overrides),
  notificationsItem: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    notificationsSharedMenuItem(MenuType.Header, overrides),
  accounts: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    accountsSharedMenuItem(MenuType.Header, overrides),
  presentations: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    presentationsSharedMenuItem(MenuType.Header, overrides),
  quoteOrders: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    quoteOrdersSharedMenuItem(MenuType.Header, overrides),
  mySite: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    mySiteSharedMenuItem(MenuType.Header, overrides),
  externalResource: (overrides?: Partial<HeaderMenuItemOverrideSettings>) =>
    externalResourceSharedMenuItem(MenuType.Header, overrides),
};

export const constructHeaderMenu = (menuItemsConfig: HeaderMenuTypes[]) =>
  constructMenu({
    menuItemsConfig,
    availableMenuItems: availableHeaderMenuItems,
  });
