import {
  makeEnvironmentProviders,
  type EnvironmentProviders,
} from '@angular/core';
import { provideStates } from '@ngxs/store';

import { RouterHistoryState } from './state';

export function provideEspRouter(): EnvironmentProviders {
  return makeEnvironmentProviders([provideStates([RouterHistoryState])]);
}
