import type {
  MobileMenuItemOverrideSettings,
  MobileMenuItemsKeyValue,
  MobileMenuTypes,
} from '@esp/misc/types-menu-items';

import {
  collectionsSharedMenuItem,
  crmSharedMenuItem,
  feedbackSharedMenuItem,
  MenuType,
  projectsSharedMenuItem,
} from './shared-menu-items.config';
import { constructMenu } from './utils';

export const availableMobileMenuItems: MobileMenuItemsKeyValue = {
  feedback: (overrides?: Partial<MobileMenuItemOverrideSettings>) =>
    feedbackSharedMenuItem(MenuType.Mobile, overrides),
  collections: (overrides?: Partial<MobileMenuItemOverrideSettings>) =>
    collectionsSharedMenuItem(MenuType.Mobile, overrides),
  projects: (overrides?: Partial<MobileMenuItemOverrideSettings>) =>
    projectsSharedMenuItem(MenuType.Mobile, overrides),
  crm: (overrides?: Partial<MobileMenuItemOverrideSettings>) =>
    crmSharedMenuItem(MenuType.Mobile, overrides),
};

export const constructMobileMenu = (menuItemsConfig: MobileMenuTypes[]) =>
  constructMenu({
    menuItemsConfig,
    availableMenuItems: availableMobileMenuItems,
  });
