import { provideStates } from '@ngxs/store';

import { AlgoliaTasksInterceptor } from '@esp/search/data-access-algolia-tasks';

import { CollectionsActions } from './actions';
import {
  CollectionProductsState,
  CollectionsSearchState,
  CollectionsState,
  RecentCollectionsState,
} from './states';

export const provideCollectionsDataAccess = () => {
  return [
    AlgoliaTasksInterceptor.create({
      urlPattern: /zeal\/collections/,
      ActionToDispatch: CollectionsActions.SearchIndexOperationComplete,
    }),
    provideStates([
      CollectionsState,
      CollectionsSearchState,
      CollectionProductsState,
      RecentCollectionsState,
    ]),
  ];
};
