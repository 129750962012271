import {
  Directive,
  inject,
  input,
  ViewContainerRef,
  type OnChanges,
  type OnDestroy,
  type SimpleChanges,
} from '@angular/core';

import { CosTeleportService } from './teleport.service';

@Directive({
  selector: '[cosTeleportOutlet]',
})
export class CosTeleportOutletDirective implements OnDestroy, OnChanges {
  readonly teleportOutlet = input.required<string>({
    alias: 'cosTeleportOutlet',
  });
  private readonly _vcr = inject(ViewContainerRef);
  private readonly _service = inject(CosTeleportService);

  ngOnChanges(changes: SimpleChanges): void {
    const teleportOutletChanges = changes['teleportOutlet'];

    if (teleportOutletChanges) {
      this._service.deleteOutlet(teleportOutletChanges.previousValue);
      this._service.newOutlet(this.teleportOutlet(), this._vcr);
    }
  }

  ngOnDestroy(): void {
    this._service.deleteOutlet(this.teleportOutlet());
  }
}
