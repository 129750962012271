import { WhiteLabelQueries } from 'esp/white-label/data-access';

import { A11yModule } from '@angular/cdk/a11y';
import { Component, inject, Injector, NgZone } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { NAVIGATOR, WINDOW } from '@ng-web-apis/common';
import { Store } from '@ngxs/store';
import { distinctUntilChanged, take } from 'rxjs';

import { startAppCues } from '@cosmos/analytics/appcues';
import { CosAnalyticsService } from '@cosmos/analytics/common';
import { startHeapIo } from '@cosmos/analytics/heap-io';
import { CosGlobalLayoutModule } from '@cosmos/layouts/ui-global-layout';
import { injectRouteData } from '@cosmos/router';
import { RolesEnum } from '@cosmos/types-common';
import {
  Breakpoint,
  injectIsScreenNarrowerThan,
} from '@cosmos/util-screen-size';
import { AuthFacade, HasRolePipe } from '@esp/auth/data-access-auth';
import { GlobalFooterComponent } from '@esp/misc/feature-global-footer';
import { GlobalHeaderComponent } from '@esp/misc/feature-global-header';
import { GlobalTabsComponent } from '@esp/misc/feature-global-tabs';
import { SwUpdateService } from '@esp/notifications/feature-notifications-refresh';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'esp-app-root',
  templateUrl: './app-root.component.html',
  styleUrls: ['./app-root.component.scss'],
  imports: [
    A11yModule,
    GlobalHeaderComponent,
    GlobalFooterComponent,
    CosGlobalLayoutModule,
    GlobalTabsComponent,
  ],
})
export class AppRootComponent {
  readonly routeData = injectRouteData();
  readonly isScreenNarrowerThanMD = injectIsScreenNarrowerThan(Breakpoint.MD);
  readonly whiteLabelSettings = this._store.selectSignal(
    WhiteLabelQueries.getSettings
  );
  readonly hasWhiteLabelName = this._store.selectSignal(
    WhiteLabelQueries.isWhiteLabelNameSet
  );

  constructor(
    private readonly _ngZone: NgZone,
    private readonly _router: Router,
    private readonly _authFacade: AuthFacade,
    private readonly _analytics: CosAnalyticsService,
    private readonly _swUpdate: SwUpdateService,
    private readonly _store: Store
  ) {
    this._ensureValidProductionDomain();
    this._setupAnalytics();
    this._swUpdate.waitForUpdate();
  }

  navigateByUrl(url: string): void {
    // Caretaker note: this method is used for e2e tests
    this._ngZone.run(() => {
      this._router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this._router.navigateByUrl(url));
    });
  }

  private _setupAnalytics(): void {
    const injector = inject(Injector);
    const hasRolePipe = inject(HasRolePipe);
    const user$ = this._authFacade.getUser();

    // We should wait until the user is logged in before loading Heap.
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    user$.pipe(take(1)).subscribe(() => startHeapIo(injector));

    startAppCues({
      injector,
      identifyConfigTransformer: (config) => {
        config.PreferredSupplierAdmin = hasRolePipe.transform(
          RolesEnum.PVAdmin
        );
        return config;
      },
    });

    user$
      .pipe(
        distinctUntilChanged((a, b) => a?.Id === b?.Id),
        takeUntilDestroyed()
      )
      .subscribe(
        ({
          Id,
          Name,
          Email,
          GivenName,
          FamilyName,
          TenantCode,
          TenantId,
          AsiNumber,
          CompanyId,
          CompanyName,
          IsAdmin,
          IsDistributor,
          IsSupplier,
          IsCorporate,
          IsInternal,
          displayName,
          UserName,
        }) => {
          this._analytics.userEvent$.next({
            userId: Id.toString(),
            traits: {
              Name,
              Email,
              GivenName,
              FamilyName,
              TenantCode,
              TenantId,
              AsiNumber,
              CompanyId,
              CompanyName,
              IsAdmin,
              IsDistributor,
              IsSupplier,
              IsCorporate,
              IsInternal,
              displayName,
              UserName,
            },
          });
        }
      );
  }

  private async _ensureValidProductionDomain() {
    if ((ngDevMode && typeof jest !== 'undefined') || global_isServeMode)
      return;

    const { location } = inject(WINDOW);
    const currentDomain = location.host;

    // See `on-pr-or-push.yml`.
    const previewSubdomain = 'delightful-pebble';

    // We don't need to redirect to the ESP+ domain
    // because we're on a preview domain.
    if (currentDomain.includes(previewSubdomain)) return;

    const espPlusDomain = process.env.ESP_PLUS_DOMAIN;
    if (espPlusDomain && currentDomain !== espPlusDomain) {
      const navigator = inject(NAVIGATOR);

      try {
        // We need to wait until the service worker is unregistered
        // because the browser may initiate navigation to the new host
        // before the worker is unregistered. This could occur if the
        // ESP+ domain has already been resolved and cached previously.
        const registrations = await navigator.serviceWorker.getRegistrations();
        await Promise.all(
          registrations.map((registration) => registration.unregister())
        );
      } finally {
        location.host = espPlusDomain;
      }
    }
  }
}
