@let _routeData = routeData();

@if (_routeData) {
  <cos-global-layout>
    @if (!_routeData.hideGlobalHeader) {
      <esp-global-header
        cosGlobalLayoutNav
        [hideGlobalSearch]="!!_routeData.hideGlobalSearch"
        [hideGlobalNav]="!!_routeData.hideGlobalNav"
      />
    }

    @if (!_routeData.hideGlobalFooter) {
      <esp-global-footer
        cosGlobalLayoutFooter
        [hasWhiteLabelName]="hasWhiteLabelName()"
        [whiteLabelSettings]="whiteLabelSettings()"
      />
    }

    @if (
      !_routeData.hideGlobalHeader &&
      !_routeData.hideGlobalNav &&
      isScreenNarrowerThanMD()
    ) {
      <esp-global-tabs cosGlobalLayoutFooterTabs />
    }
  </cos-global-layout>
}
