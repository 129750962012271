import { WhiteLabelQueries } from 'esp/white-label/data-access';

import { Injectable } from '@angular/core';

import { fromSelector, LocalState } from '@cosmos/state';

@Injectable()
export class GlobalTabsLocalState extends LocalState<GlobalTabsLocalState> {
  readonly whiteLabelSettings = fromSelector(WhiteLabelQueries.getSettings);
  readonly isWhiteLabelNameSet = fromSelector(
    WhiteLabelQueries.isWhiteLabelNameSet
  );
}
