<div class="global-footer">
  <div class="flex flex-col" [class]="{ 'grid-layout-1': !isMobileOrTablet() }">
    @if (vm()) {
      @defer (when vm().navItems.length) {
        <esp-column-layout-footer
          [navItems]="vm().navItems"
          
        />
      }

      @defer (when !vm().navItems.length) {
        <cos-global-footer
          
          [supportSubject]="supportSubject()"
          [feedbackSubject]="feedbackSubject()"
        />
      }
    }
  </div>

  @if (hasWhiteLabelName() && vm() && vm().logoUrl) {
    <div class="bg-base-100 mt-2">
      <div class="grid-layout-1 flex justify-between items-center py-2 px-4">
        <div>
          <img
            [src]="vm().logoUrl"
            height="40px"
            
          />
        </div>
      </div>
    </div>
  }
</div>
