import {
  APP_INITIALIZER,
  ChangeDetectionStrategy,
  Component,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';

import { CosSlideToggleModule } from '@cosmos/components/toggle';
import { LocalStorageService } from '@cosmos/data-access-storage';
import { CosFeatureFlagsToolbarControlsSet } from '@cosmos/feature-flags-toolbar';
import { CosmosTranslocoService } from '@cosmos/util-translations';

@Component({
  selector: 'cos-feature-flags-translations-control',
  templateUrl: './feature-flags-translations-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CosSlideToggleModule],
})
export class CosFeatureFlagsTranslationsControlComponent {
  private readonly _localStorageService = inject(LocalStorageService);

  readonly showTranslations =
    !!this._localStorageService.getItem<boolean>('showTranslations');

  constructor(private readonly _translocoService: CosmosTranslocoService) {}

  toggleTranslations(checked: boolean): void {
    this._localStorageService.setItem('showTranslations', checked);
    this._translocoService.setActiveLang(
      this._translocoService.getActiveLang()
    );
  }
}

export function withFlagsTranslations() {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        const controls = inject(CosFeatureFlagsToolbarControlsSet);
        return () => controls.add(CosFeatureFlagsTranslationsControlComponent);
      },
    },
  ]);
}
