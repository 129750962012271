// TODO(ds): imports between cosmos/config and cosmos/analytics should be refactored
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { Injector } from '@angular/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { ConfigService } from '@cosmos/config';
import { runOutsideAngular } from '@cosmos/util-common/run-outside-angular';

import type { HeapIoConfig } from './types';

export * from './types';
export function startHeapIo(injector: Injector): void {
  if (global_isServer || (ngDevMode && typeof jest !== 'undefined')) {
    return;
  }

  const config: HeapIoConfig = injector.get(ConfigService).get('heapIo');

  if (config.enabled) {
    runOutsideAngular(() => {
      import('./heap-io').then((m) =>
        injector.get(m.HeapIoService).start(config)
      );
    });
  }
}
