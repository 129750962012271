import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  type OnDestroy,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { ResizeObserverDirective } from '@cosmos/ui-resize-observer-directive';
import { CosTeleportOutletDirective } from '@cosmos/util-teleport';

@Component({
  selector: 'cos-global-layout',
  imports: [RouterOutlet, ResizeObserverDirective, CosTeleportOutletDirective],
  templateUrl: './global-layout.component.html',
  styleUrls: ['./global-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'cos-global-layout',
  },
})
export class CosGlobalLayoutComponent implements OnDestroy {
  private readonly _document = inject(DOCUMENT);

  footerTabsResized(entry: ResizeObserverEntry) {
    const height = entry.contentRect.height;
    this._document.documentElement.style.setProperty(
      `--global-layout-footer-tabs-height`,
      `${height}px`
    );
  }

  navResized(entry: ResizeObserverEntry) {
    const height = entry.contentRect.height;
    this._document.documentElement.style.setProperty(
      `--global-layout-header-height`,
      `${height}px`
    );
  }

  ngOnDestroy(): void {
    this._document.documentElement.style.setProperty(
      `--global-layout-footer-tabs-height`,
      `0px`
    );

    this._document.documentElement.style.setProperty(
      `--global-layout-header-height`,
      `0px`
    );
  }
}
