import {
  APP_INITIALIZER,
  ChangeDetectionStrategy,
  Component,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { CosButtonModule } from '@cosmos/components/button';
import { CosTooltip } from '@cosmos/components/tooltip';
import { LocalStorageService } from '@cosmos/data-access-storage';
import { CosFeatureFlagsToolbarControlsSet } from '@cosmos/feature-flags-toolbar';
import { DialogService } from '@cosmos/ui-dialog';
import { CosToastService } from '@cosmos/ui-notification-toast';
import { ClipboardService } from '@cosmos/util-clipboard';
import { injectDestroyRef } from '@cosmos/util-common';

import { authDataDialogConfig } from './dialog/auth-data-dialog.config';

@Component({
  selector: 'cos-feature-flags-auth-data',
  templateUrl: './feature-flags-auth-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CosButtonModule, CosTooltip],
})
export class CosFeatureFlagsAuthDataComponent {
  private readonly _clipboardService = inject(ClipboardService);
  private readonly _dialogService = inject(DialogService);
  private readonly _destroyRef = injectDestroyRef();
  private readonly _localStorageService = inject(LocalStorageService);
  private readonly _toastService = inject(CosToastService);

  protected openDetailsModal() {
    this._dialogService
      .open(authDataDialogConfig)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe();
  }

  protected copyToken() {
    const rawAuthData =
      this._localStorageService?.getItem<Record<string, unknown>>('esp:auth');
    const accessToken = (rawAuthData?.['session'] as Record<string, string>)?.[
      'access_token'
    ];

    this._clipboardService.copyToClipboardAsync(accessToken || '');
    this._toastService.showToast({
      title: 'Text copied',
      body: 'Access token was copied to your clipboard',
      type: 'info',
    });
  }
}

export function withAuthData() {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        const controls = inject(CosFeatureFlagsToolbarControlsSet);
        return () => controls.add(CosFeatureFlagsAuthDataComponent);
      },
    },
  ]);
}
