import { inject, type EnvironmentProviders } from '@angular/core';
import {
  provideRouter,
  Router,
  withComponentInputBinding,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
  type RouterConfigOptions,
} from '@angular/router';
import { provideStates } from '@ngxs/store';

import { AuthTokenGuard } from '@asi/auth/data-access-auth';
import { featureFlagGuard } from '@cosmos/feature-flags';
import { NetworkAwarePreloadStrategy, type TypedRoute } from '@cosmos/router';
import type { FeatureFlagsRouteData } from '@cosmos/types-feature-flags';
import { LanguageScope } from '@cosmos/util-translations';
import { authGuard } from '@esp/auth/data-access-auth';
import { redirectGuard } from '@esp/auth/feature-shell-web';
import { LoadRanksResolver } from '@esp/preferred-suppliers/data-access-resolvers';
import {
  provideCustomRouterStateSerializer,
  provideEspRouter,
} from '@esp/router';
import { WebsiteLicensesState } from '@esp/websites/data-access-license';
import { whiteLabelResolver } from '@esp/white-label/feature';

const ROUTER_OPTIONS: RouterConfigOptions = {
  onSameUrlNavigation: 'reload',
};

function getTitle(key: string) {
  return `${LanguageScope.EspRouting}.route-titles.${key}`;
}

const routes: TypedRoute<FeatureFlagsRouteData>[] = [
  {
    path: '',
    pathMatch: 'full',
    children: [],
    data: {
      defaultPath: 'home',
    },
    canMatch: [redirectGuard],
  },
  {
    path: 'login/account/redirector',
    children: [],
    canActivate: [AuthTokenGuard],
  },
  {
    path: 'auth',
    loadChildren: async () =>
      (await import('@esp/auth/feature-shell-web')).routes,
  },
  {
    path: '',
    resolve: [whiteLabelResolver],
    providers: [provideStates([WebsiteLicensesState])],
    children: [
      {
        path: 'home',
        loadChildren: async () => await import('@esp/home/feature-home'),
        data: { preload: 'always', hideGlobalSearch: true },
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'crm',
        loadChildren: async () =>
          (await import('@esp/crm/feature-shell-web'))
            .EspCrmFeatureShellWebModule,
        data: { preload: true },
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'emails',
        loadChildren: async () =>
          (await import('@esp/emails/feature-shell-web')).routes,
        canActivate: [authGuard.canActivate],
        data: {
          preload: true,
        },
      },
      {
        path: 'dashboard',
        loadChildren: async () =>
          (await import('@esp/dashboard/feature-shell-web')).routes,
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'tasks',
        loadChildren: async () =>
          (await import('@esp/tasks/feature-global-tasks')).routes,
        canActivate: [authGuard.canActivate],
        data: {
          preload: true,
        },
      },
      {
        path: 'settings',
        loadChildren: async () =>
          (await import('@esp/settings/feature-shell-web')).routes,
        canActivate: [authGuard.canActivate],
        data: { preload: true },
      },
      {
        path: 'preferred-suppliers',
        loadChildren: async () =>
          (await import('@esp/preferred-suppliers/feature-shell-web')).routes,
        canActivate: [authGuard.canActivate],
        resolve: [LoadRanksResolver],
        data: { preload: true },
      },
      {
        path: 'products',
        loadChildren: async () =>
          (await import('@esp/products/feature-shell-web'))
            .EspProductsFeatureShellWebModule,
        data: { preload: 'always' },
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'suppliers',
        loadChildren: async () =>
          (await import('@esp/suppliers/feature-shell-web')).routes,
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'my-searches',
        loadChildren: async () =>
          (await import('@esp/my-searches/feature-shell-web')).routes,
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'decorators',
        loadChildren: async () =>
          (await import('@esp/decorators/feature-shell-web'))
            .EspDecoratorsFeatureShellWebModule,
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'collections',
        loadChildren: async () =>
          (await import('@esp/collections/feature-shell-web')).routes,
        data: {
          meta: {
            title: getTitle('collections.search'),
          },
        },
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'orders',
        loadChildren: async () =>
          (await import('@esp/orders/feature-shell-web'))
            .EspOrdersFeatureShellWebModule,
        data: {
          preload: true,
          analytics: {
            page: 'OrderSearch',
          },
        },
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'presentations',
        loadChildren: () => import('@esp/presentations/feature-shell-web'),
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'projects',
        loadChildren: async () =>
          (await import('@esp/projects/feature-shell-web'))
            .EspProjectsFeatureShellWebModule,
        data: {
          preload: true,
          analytics: {
            page: 'ProjectSearch',
          },
        },
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'configure-product',
        loadChildren: () =>
          import('@esp/product-options-assistant/feature-shell-web'),
        canActivate: [authGuard.canActivate],
        data: {
          hideGlobalHeader: true,
        },
      },
      {
        path: 'license-agreement',
        loadComponent: async () =>
          (await import('@esp/auth/feature-shell-web')).LicenseAgreementPage,
        data: {
          hideGlobalHeader: true,
          hideGlobalFooter: true,
        },
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'companies',
        loadChildren: async () =>
          (await import('@esp/companies/feature-shell-web'))
            .EspCompaniesFeatureShellWebModule,
        data: { preload: true },
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'contacts',
        loadChildren: async () =>
          (await import('@esp/contacts/feature-shell-web'))
            .EspContactsFeatureShellWebModule,
        data: { preload: true },
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'websites',
        loadChildren: async () =>
          (await import('@esp/websites/feature-shell-web')).routes,
        data: { preload: true },
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'stores',
        loadChildren: async () =>
          (await import('@esp/stores/feature-shell-web')).routes,
        data: {
          preload: true,
          featureFlags: {
            matches: ['stores'],
            noMatchRedirectsTo: ['websites'],
          },
        },
        canMatch: [featureFlagGuard],
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'pim',
        loadChildren: async () =>
          (await import('@esp/custom-products/feature-shell-web')).routes,
        canActivate: [authGuard.canActivate],
      },
      {
        path: 'mobile',
        loadChildren: async () =>
          (await import('@esp/common/feature-mobile-store-page')).routes,
        data: {
          hideGlobalSearch: true,
          hideGlobalNav: true,
        },
      },
    ],
  },
  {
    loadComponent: async () =>
      (await import('@asi/auth/data-access-auth'))
        .AsiInsufficientPermissionsPage,
    path: 'unauthorized',
    resolve: [whiteLabelResolver],
  },
  {
    path: 'unsupported-browser',
    loadComponent: async () =>
      (await import('@esp/common/feature-unsupported-browser-page'))
        .UnsupportedBrowserPage,
    // Do not allow the user to navigate away from this page
    canDeactivate: [() => false],
    data: {
      hideGlobalSearch: true,
      hideGlobalNav: true,
    },
  },
  {
    path: '**',
    loadComponent: async () =>
      (await import('@cosmos/ui-not-found')).CosmosNotFoundPage,
    canMatch: [
      () => {
        // there are redirects in the app where route not being found has custom handling,
        // this allows them to skip the default treatment
        return !inject(Router).getCurrentNavigation()?.extras.state
          ?.skipNotFoundHandling;
      },
    ],
    data: {
      hideGlobalNav: true,
      hideGlobalSearch: true,
    },
  },
];

export const provideAppRouting = (): EnvironmentProviders[] => [
  provideEspRouter(),
  provideCustomRouterStateSerializer(),
  provideRouter(
    routes,
    withRouterConfig(ROUTER_OPTIONS),
    withInMemoryScrolling({
      scrollPositionRestoration: 'enabled',
    }),
    withPreloading(NetworkAwarePreloadStrategy),
    withComponentInputBinding()
  ),
];
