import {
  makeEnvironmentProviders,
  type EnvironmentProviders,
} from '@angular/core';
import { provideStates } from '@ngxs/store';

import { ContactsSearchState, ContactsState } from './states';

export function provideContactsDataAccess(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideStates([ContactsState, ContactsSearchState]),
  ]);
}
