import { KeyValuePipe } from '@angular/common';
import {
  APP_INITIALIZER,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  makeEnvironmentProviders,
  type OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { WINDOW } from '@ng-web-apis/common';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs';

import { CosFeatureFlagsToolbarControlsSet } from '@cosmos/feature-flags-toolbar';
import { FormControl } from '@cosmos/forms';
import { useLocalState } from '@cosmos/state';
import { injectDestroyRef } from '@cosmos/util-common';
import { User } from '@esp/auth/data-access-auth';
import { WhiteLabelName } from '@esp/white-label/types';

import { WhiteLabelSelectLocalState } from './white-label-select.local-state';

@Component({
  selector: 'esp-whitelabel-select',
  templateUrl: './white-label-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [KeyValuePipe, ReactiveFormsModule],
  providers: [WhiteLabelSelectLocalState],
})
export class EspWhitelabelSelectComponent implements OnInit {
  protected readonly whiteLabelOptions: Record<WhiteLabelName, string> = {
    [WhiteLabelName.None]: 'None',
    [WhiteLabelName.Vernon]: 'Vernon',
    [WhiteLabelName.AIA]: 'AIA',
    [WhiteLabelName.FullyPromoted]: 'Fully Promoted',
    [WhiteLabelName.Halo]: 'Halo',
  };
  protected readonly selectedWhiteLabelNameControl =
    new FormControl<WhiteLabelName>(WhiteLabelName.None);
  readonly state = useLocalState(WhiteLabelSelectLocalState, this);
  private readonly _destroyRef = injectDestroyRef();
  private readonly _cdRef = inject(ChangeDetectorRef);
  private readonly _window = inject(WINDOW);

  ngOnInit(): void {
    this.selectedWhiteLabelNameControl.disable({ emitEvent: false });
    this.state
      .asObservable()
      .pipe(
        map((state) => state.selectedWhiteLabelName),
        filter(Boolean),
        distinctUntilChanged(),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe((name) => {
        this.selectedWhiteLabelNameControl.setValue(name, { emitEvent: false });
        this._cdRef.markForCheck();
      });

    this.state
      .asObservable()
      .pipe(
        map((state) => state.user),
        distinctUntilChanged(),
        map((user) => (user ? new User(user).isAdministrator : false)),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe((enabled) => {
        enabled
          ? this.selectedWhiteLabelNameControl.enable({ emitEvent: false })
          : this.selectedWhiteLabelNameControl.disable({ emitEvent: false });
        this._cdRef.markForCheck();
      });

    this.selectedWhiteLabelNameControl.valueChanges
      .pipe(
        switchMap((name) => this.state.selectWhiteLabelName(name)),
        tap(() => this._window.location.reload()),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }
}

export function withWhiteLabelSelect() {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        const controls = inject(CosFeatureFlagsToolbarControlsSet);
        return () => controls.add(EspWhitelabelSelectComponent);
      },
    },
  ]);
}
