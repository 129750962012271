import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';

import type { MenuItem } from '@cosmos/types-layout';
import { CosGlobalFooterComponent } from '@cosmos/ui-global-footer';
import { isMobileOrTablet } from '@cosmos/util-screen-size';
import { constructFooterMenu } from '@esp/misc/feature-menu-items';
import type { WhiteLabelSettings } from '@esp/white-label/types';

import { ColumnLayoutFooterComponent } from './components/column-layout-footer/column-layout-footer.component';

@Component({
  selector: 'esp-global-footer',
  templateUrl: './global-footer.component.html',
  styleUrl: './global-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ColumnLayoutFooterComponent, CosGlobalFooterComponent],
})
export class GlobalFooterComponent {
  readonly supportSubject = input('ESP+ Support Inquiry');
  readonly feedbackSubject = input('ESP+ Feedback');
  readonly hasWhiteLabelName = input(false);
  readonly whiteLabelSettings = input<WhiteLabelSettings | null>();

  readonly isMobileOrTablet = isMobileOrTablet();

  readonly vm = computed<{ navItems: MenuItem[]; logoUrl: string }>(() => {
    const settings = this.whiteLabelSettings();
    if (!settings) {
      return { navItems: [], logoUrl: '' };
    }
    const footerMenuItems = constructFooterMenu(settings.FooterMenu || []);
    return {
      navItems: footerMenuItems,
      logoUrl: settings.LogoUrlColored ?? settings.LogoUrl,
    };
  });
}
