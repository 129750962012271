import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';

import type {
  CosGlobalTabsNavigationItem,
  NavigationItem,
} from '@cosmos/types-layout';
import { CosNavigationComponent } from '@cosmos/ui-navigation';

@Component({
  selector: 'cos-global-tabs',
  imports: [CosNavigationComponent],
  templateUrl: 'global-tabs.component.html',
  styleUrl: 'global-tabs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'cos-global-tabs',
  },
})
export class CosGlobalTabsComponent {
  readonly navigation = input.required<CosGlobalTabsNavigationItem[]>();
  readonly navigationName = input<string>();

  readonly navigationItems = computed(() => {
    const navigation = this.navigation();
    return navigation && this._mapNavigation(navigation);
  });

  private _mapNavigation(
    items: CosGlobalTabsNavigationItem[]
  ): NavigationItem[] {
    return items.map((item) => {
      return this._mapNavigationItem(item);
    });
  }

  private _mapNavigationItem(
    item: CosGlobalTabsNavigationItem
  ): NavigationItem {
    const classes = ['cos-global-tabs-item', item.classes ? item.classes : ''];

    return {
      ...item,
      type: 'item',
      classes: classes.join(' '),
      children: undefined,
    };
  }
}
