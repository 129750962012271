<div (cosOnResize)="navResized($event)" class="cos-global-layout__nav">
  <ng-content select="[cosGlobalLayoutNav]" />
  <ng-container cosTeleportOutlet="cosGlobalLayoutNavTeleport" />
</div>

<div class="cos-global-layout__content">
  <router-outlet />
</div>

<div class="cos-global-layout__footer">
  <ng-content select="[cosGlobalLayoutFooter]" />
</div>

<div (cosOnResize)="footerTabsResized($event)" class="cos-global-layout__tabs">
  <ng-container cosTeleportOutlet="cosGlobalLayoutFooterTabTeleport" />
  <ng-content select="[cosGlobalLayoutFooterTabs]" />
</div>
