import { inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { matches } from 'lodash-es';
import { filter, map, Observable } from 'rxjs';

import type { DefaultRouteData } from '../types';

/**
 * Since this injector can (and was built for) be used before the route-outlet, we need
 * to dive into child routes to get the deepest route data.
 *
 * This is a alternative to RouterFacade.
 */
export const injectRouteData = <RouterData extends DefaultRouteData>() => {
  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);

  const routerData$ = router.events.pipe(
    // Only consider NavigationEnd events
    filter((event) => event instanceof NavigationEnd),
    map(() => {
      let route = activatedRoute;
      let lastRouteWithData = activatedRoute;

      // Traverse to the deepest active child route, but take last one with data
      while (route.firstChild) {
        route = route.firstChild;

        if (route.snapshot.data && !matches(route.snapshot.data)({}))
          lastRouteWithData = route;
      }

      return lastRouteWithData;
    }),
    map((route) => route.snapshot.data),
    filter(Boolean)
  );

  return toSignal(routerData$ as Observable<RouterData>);
};
